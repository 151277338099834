<template>
  <v-col class="align-self-center">
    <v-row class="justify-center text-center">
      <v-progress-circular
        :size="70"
        color="#055289"
        indeterminate
      />
    </v-row>

    <v-row
      v-if="currentMessage"
      class="caption justify-center pt-3"
    >
      <span>
        {{ currentMessage.text }}
      </span>
    </v-row>
  </v-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('messages')

export default {
  name: 'LoadingMessage',
  computed: {
    ...mapGetters(['currentMessage']),
  },
}
</script>
